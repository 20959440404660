<template>
	<div>
		<div class="generic-title">Terms of Use</div>
		<div class="legal-content">
            <p><strong>Acceptance of Terms of Service</strong><br></p>
            <p>This Terms of Service (“TOS”) is a legally binding agreement made by and between Fine Grounds, LLC and its affiliates, (“Fine Grounds”, “we”, “us” and “our”), and you, personally and, if applicable, on behalf of the entity for whom you are using this website (collectively, “you”, “your”, “yours”). This TOS governs your access to and use on finegrounds.app or any other website owned, operated, licensed, or controlled by Fine Grounds (the “website”), and any associated software, website, web widgets, feeds and applications for third-party websites and services, and any other mobile or web services or applications owned, controlled, or offered by Fine Grounds (collectively with the website, the “Service”).</p>
            <p>BY ACCESSING OR USING ANY PART OF THE SERVICE, INCLUDING LINKING TO THE WEBSITE, YOU AGREE THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY THIS TOS. IF THERE ARE ADDITIONAL POSTED GUIDELINES OR POLICIES APPLICABLE TO THE SERVICES OR WEBSITE (INCLUDING THE PRIVACY POLICY), YOU ARE REQUIRED TO FOLLOW THOSE AS WELL. THOSE POLICIES AND GUIDELINES ARE INCORPORATED BY REFERENCE INTO THIS TOS. IF YOU DO NOT AGREE TO BE BOUND BY THE TOS AND TO FOLLOW ALL APPLICABLE LAWS, GUIDELINES AND POLICIES, DO NOT ACCESS OR USE THE SERVICES AND LEAVE THE WEBSITE IMMEDIATELY.</p>
            <p>Fine Grounds RESERVES THE RIGHT TO MAKE CHANGES TO THIS TOS AT ANY TIME. YOUR CONTINUED USE OF THE SERVICE CONSTITUTES ASSENT TO ANY NEW OR MODIFIED PROVISION OF THIS TOS THAT MAY BE POSTED ON THE WEBSITE. We will post the amended TOS on this page and indicate at the top of the page the date the Agreement was last revised.</p>
            <p><strong>Eligibility</strong></p>
            <p>You must be at least 18 years old to download and use the Service, or, if you are not at least 18, you must be at least 13 years old and may download and/or use the Service only in conjunction with, and under the supervision of, your parent or guardian. If you do not qualify, you may not visit the website or download or use the Service.</p>
            <p><strong>Use of the Software Generally</strong></p>
            <p>You must comply with all of the terms and conditions of this TOS, any policies referred to below or on the website, and all applicable laws, regulations and rules when you use the website. Subject to the terms and conditions of this TOS, you are hereby granted a limited, revocable, non-exclusive right to use the Services and the content and materials on the website in the normal course of your use of the website.</p>
            <p><strong>Restrictions</strong></p>
            <p>Except as expressly authorized herein, you agree to the following restrictions:</p>
            <p>(a) You may not use, copy, modify, translate, publicly perform or display, transmit, publish, edit, adapt, reproduce, or transfer the right to use the Service except as expressly provided in this TOS.</p>
            <p>(b) You may not distribute, sell, resell, sublicense, rent, lease, share, or lend the Service.</p>
            <p>(c) You may not reverse engineer, reverse compile, disassemble, or otherwise attempt to discover the source code of the Service or create derivative works based on the Service.</p>
            <p>(d) You agree that you shall only use the Service in a manner that complies with all applicable laws in the jurisdiction in which you use the Service, including, but not limited to, applicable restrictions concerning privacy, copyright and other intellectual property rights.</p>
            <p>(e) You agree not to upload, post, e-mail or otherwise send or transmit any material that contains viruses or any other computer code, files or programs designed to interrupt, destroy, usurp, modify or limit the functionality of the Service or any computer software, hardware, telecommunications equipment or other equipment or devices associated with the Service. You also agree not to interfere with the servers, networks or other devices or equipment connected to or used in connection with the Service or to violate any of the procedures, policies or regulations of such networks, devices or equipment, or of any service providers associated with or connected to the Service.</p>
            <p>(f) We do not promote, recommend or condone use of the Service during other activities, such as the operation of machinery, where there is any risk of accident. You agree not to use the Service during such activities.</p>
            <p><strong>Mobile</strong></p>
            <p>Fine Grounds may make the Service accessible on certain mobile devices. Please be aware that your carrier’s normal rates and fees, such as text messaging and data fees, may apply.</p>
            <p><strong>Privacy Policy</strong></p>
            <p>The Service may store and provide to Fine Grounds data about your interaction with the Service, including without limitation, data related to the content accessed while using the Service. Such information will be used only for the purposes of operating, providing and improving the Service and marketing the Service, but only to the extent such information is aggregated and anonymized. Such information is subject to the terms of Fine Grounds’s Privacy Policy, which is incorporated by reference into this TOS.</p>
            <p><strong>Electronic Communications</strong></p>
            <p>By downloading and/or using the Service, you consent to receiving electronic communications and notices from Fine Grounds. You agree that any notice, agreement, disclosure or other communications that we send to you electronically will satisfy any applicable legal communication requirements, including that such communications be in writing.</p>
            <p><strong>Intellectual Property</strong></p>
            <p><em>Copyright.</em> All materials on the website and available through the Services, including without limitation, the logos, design, text, graphics, audio clips, video clips, other files, and the selection, arrangement and organization thereof are owned by Fine Grounds or its licensors.</p>
            <p><em>Trademarks.</em> Product names, logos, designs, titles, and words or phrases used on the Service, are owned by Fine Grounds or its licensors. All page headers, custom graphics, button icons and scripts are trademarks or trade dress of Fine Grounds or its licensors. If you use such trademarks or logos, you must include appropriate attribution. All other trademarks, trade names and the like that appear on the website or the Services are the property of their respective owners. You may not use any of these trademarks, trade dress, or trade names, or any confusingly similar marks, dress or names, including without limitation as a part of any link, without express permission.</p>
            <p><em>Your Ideas. </em>You may choose to or we may invite you to submit comments, testimonials, feedback, suggestions, ideas, and other submissions about the Services, including without limitation about how to improve the Services or our products (“Ideas”). By submitting any Idea, you agree that your disclosure is gratuitous, unsolicited and without restriction and will not place Fine Grounds under any fiduciary or other obligation, that we are free to disclose the Ideas on a non-confidential basis to anyone or otherwise use the Ideas without any additional compensation to you. You acknowledge that, by acceptance of your submission, Fine Grounds does not waive any rights to use similar or related ideas previously known to Fine Grounds, or developed by its employees, or obtained from sources other than you. Such disclosure, submission or offer of any Ideas shall, and hereby does, constitute a perpetual, royalty-free, worldwide, irrevocable license to us of all right, title and interest in all patent, copyright, trademark, and all other intellectual property and other rights whatsoever in and to the Ideas and a waiver of any claim based on moral rights, unfair competition, breach of implied contract, breach of confidentiality, and any other legal theory. You should not submit any Ideas to us if you do not wish to license such rights to us. We are and will be under no obligation: (i) to maintain any Ideas in confidence; (ii) to pay to you or any third party any compensation for any Ideas; or (iii) to respond to any Ideas. You are and shall remain solely responsible for the content of any Ideas you make.</p>
            <p>Linking and Framing. You may not frame, inline link, or similarly display any Fine Grounds content or property, including, without limitation, the website.</p>
            <p><strong>Notice and Take Down Procedures And Copyright Agent</strong></p>
            <p>Fine Grounds respects the intellectual property rights of others and expects its users to do the same. Fine Grounds may remove content that in its sole discretion appears to infringe the intellectual property rights of others. In addition, Fine Grounds will, in its discretion, terminate the accounts of users who infringe the intellectual property rights of others. If you believe any materials accessible on or from the Services infringe your copyrights or trademark/service mark rights, please notify Fine Grounds at info@finegrounds.app, and provide the following information:</p>
            <p>1. Identification of the copyrighted work that you believe to be infringed. Please describe the work, and where possible include a copy or the location (e.g., URL) of an authorized version of the work.</p>
            <p>2. Identification of the material that you believe to be infringing and its location. Please describe the material, and provide us with its URL or any other pertinent information that will allow us to locate the material.</p>
            <p>3. Your name, address, telephone number and email address.</p>
            <p>4. A statement that you have a good faith belief that the complained of use of the materials is not authorized by the copyright owner, its agent, or the law.</p>
            <p>5. A statement that the information that you have supplied is accurate, and indicating that “under penalty of perjury,” you are the copyright owner or are authorized to act on the copyright owner’s behalf.</p>
            <p>6. A signature or the electronic equivalent from the copyright holder or authorized representative.</p>
            <p>In an effort to protect the rights of copyright owners, we maintain a policy for the termination, in appropriate circumstances, of subscribers and account holders who are repeat infringers.</p>
            <p><strong>Advertising/Third Party Offers</strong></p>
            <p>In connection with your use of the Service, the Service may contain links to third party web sites, advertisements, or programs that are not controlled by or affiliated with Fine Grounds. Fine Grounds is not responsible for the content, offers or privacy policies of such third party advertising, sites and programs. Your dealings with third party sites are solely between you and the applicable third party. You expressly release Fine Grounds from any and all liability arising from your use of any third-party website or services or third party owned content. We encourage you to be aware of when you leave the website, and to read the terms and conditions and privacy policy of any third-party website or service that you visit.</p>
            <p><strong>Representations and Warranties</strong></p>
            <p>You represent and warrant to Fine Grounds that: (i) you have the full power and authority to enter into and perform your obligations under this TOS; (ii) your assent to and performance of your obligations under this TOS does not constitute a breach of or conflict with any other agreement or arrangement by which you are bound, or any applicable laws, regulations or rules; (iii) this TOS constitutes legal, valid and binding obligations on you, enforceable in accordance with its terms and conditions; (iv) you will not infringe the patent, copyright, trademark, trade secret, right of publicity or other intellectual property or proprietary right of any third party in your use of the website or the Services; and (v) you will comply with all applicable laws, rules and regulations in your use of the Services and the website, including this TOS.</p>
            <p><strong>Indemnification; Hold Harmless; Release</strong></p>
            <p>You agree to indemnify, hold harmless and release Fine Grounds, its officers, directors, stockholders, affiliates, and its and their licensors, suppliers and partners, from any claims, losses, damages, liabilities, including attorney’s fees, arising out of (i) your use or misuse of the Service, (ii) breach of any of this Agreement, or (iii) your violation of any applicable law or the rights of any other person or entity. Fine Grounds reserves the right, at our own expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us and you agree to cooperate with our defense of these claims.</p>
            <p><strong>Disclaimers, Exclusions, and Limitations</strong></p>
            <p><em>DISCLAIMER OF WARRANTIES.</em> Fine Grounds PROVIDES THE WEBSITE AND SERVICES ON AN “AS IS” AND “AS AVAILABLE” BASIS. Fine Grounds DOES NOT REPRESENT OR WARRANT THAT THE WEBSITE OR SERVICES OR THEIR USE (I) WILL BE UNINTERRUPTED OR SECURE, (II) WILL BE FREE OF DEFECTS, INACCURACIES OR ERRORS, (III) WILL MEET YOUR REQUIREMENTS, (IV) WILL OPERATE IN THE CONFIGURATION OR WITH OTHER HARDWARE OR SOFTWARE YOU USE, OR (V) THAT ERRORS WILL BE CORRECTED. Fine Grounds MAKES NO WARRANTIES OTHER THAN THOSE MADE EXPRESSLY IN THIS TOS, AND HEREBY DISCLAIMS ANY AND ALL IMPLIED WARRANTIES, INCLUDING WITHOUT LIMITATION, WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE, MERCHANTABILITY AND NON- INFRINGEMENT. ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES OR WEBSITE IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM SUCH DOWNLOAD.</p>
            <p><em>EXCLUSION OF DAMAGES.</em> To the maximum extent permitted by applicable law, in no event shall Fine Grounds, its affiliates, associates, dealers, agents or suppliers be liable for any , indirect, exemplary, punitive, special, incidental or consequential damages whatsoever (including but not limited to damages arising from breach of contract, warranty, tort or strict liability for loss of profits, loss of data, loss of goodwill, for business interruption, for personal injury, for loss of privacy, for failure to meet any duty including of good faith or of reasonable care, for negligence, and for any other pecuniary or other loss whatsoever), arising out of or in any way related to the use of or inability to use the website or Services, regardless of the cause of action on which they are based, even if Fine Grounds or such other entities have been advised of the possibility of such damages.</p>
            <p><em>LIMITATION OF LIABILITY.</em> IN NO EVENT WILL THE LIABILITY OF Fine Grounds IN CONNECTION WITH THIS TOS, THE WEBSITE OR SERVICES EXCEED $100.</p>
            <p><em>ADDITIONAL RIGHTS.</em> YOU MAY HAVE OTHER RIGHTS WHICH MAY VARY FROM STATE TO STATE OR IN OTHER JURISDICTIONS. BECAUSE SOME STATES OR JURISDICTIONS MAY NOT ALLOW LIMITATIONS ON THE DURATION OF IMPLIED WARRANTIES, OR LIMITATIONS ON OR EXCLUSIONS OF CONSEQUENTIAL OR INCIDENTAL DAMAGES, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU DEPENDING ON YOUR STATE OF RESIDENCE.</p>
            <p><strong>Technical Support</strong></p>
            <p>Fine Grounds has no obligation to furnish you with technical support unless separately agreed in writing between you and Fine Grounds.</p>
            <p><strong>International</strong></p>
            <p>The website and Services are controlled and operated from facilities in the United States. Fine Grounds makes no claims that the Software may be lawfully accessed, used or downloaded outside of the United States. Any such use of the Software and/or the Materials may not be lawful by certain persons or in certain territories. If you access any of these from outside of the United States, you do so at your own risk and are responsible for compliance with the laws of whatever jurisdiction you are in at the time.</p>
            <p><strong>Force Majeure</strong></p>
            <p>Fine Grounds will not be liable for failing to perform under this TOS by the occurrence of any event beyond its reasonable control, including, without limitation, a labor disturbance, an Internet outage or interruption of service, a communications outage, failure by a service provider to Fine Grounds to perform, fire, terrorism, natural disaster or war.</p>
            <p><strong>Disuptes; Limitations of Actions</strong></p>
            <p>If any dispute arises regarding this Agreement or the Service, you agree that the dispute will be governed by the laws of the State of Oregon without regard to its conflict of law provisions. You agree to personal jurisdiction and venue in the state and federal courts in Jackson County, Oregon. You acknowledge and agree that, regardless of any statute or law to the contrary, any claim or cause of action you may have arising out of, relating to, or connected with your use of the website or Services, must be filed within one calendar year after such claim or cause of action arises, or forever be barred.</p>
            <p><strong>Changes to the Website or Service</strong></p>
            <p>Fine Grounds may, in its sole discretion, change, modify, suspend, make improvements to or discontinue any aspect of the website or the Services, temporarily or permanently, at any time without notice to you, and Fine Grounds will not be liable for doing so. Without limiting the foregoing, if you do not agree with any changes made to the website you may terminate your account as set forth below.</p>
            <p><strong>Termination</strong></p>
            <p>Your rights under this Agreement will automatically terminate without notice from Fine Grounds if you fail to comply with any term of this Agreement. In case of such termination, you must cease all use of the Software and Fine Grounds may immediately restrict your access to the Service.</p>
            <p><em>Effect of Termination.</em> If your account is terminated, Fine Grounds may, in its sole discretion, delete any websites, files, graphics or other content or materials relating to your use of the website or Services on servers owned or operated for Fine Grounds or otherwise in its possession, and Fine Grounds will have no liability to you or any third party for doing so. Following termination, you will not be permitted to use the website or the Services. If your account or your access to the website or Services is terminated, Fine Grounds reserves the right to exercise whatever means it deems necessary to prevent unauthorized access to the website or the Services, including, but not limited to, technological barriers, IP mapping and direct contact with your Internet Service Provider (ISP). If your account is terminated, you must immediately pay Fine Grounds any fees that you owe it. Regardless of whether you have the right to access or use the website or the Services, this TOS will survive indefinitely unless and until Fine Grounds chooses to terminate it.</p>
            <p><strong>Waiver</strong></p>
            <p>Any provision of this Agreement may be waived only by a written instrument executed by the party entitled to the benefit of such provision. The failure of any party at any time to require performance of any provision of this Agreement shall in no manner affect such party’s right at a later time to enforce the same. A waiver of any breach of any provision of this Agreement shall not be construed as a continuing waiver of other breaches of the same or other provisions of this Agreement.</p>
            <p><strong>Severability</strong></p>
            <p>If any provision of this Agreement shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from this Agreement and shall not affect the validity and enforceability of any remaining provisions.</p>
            <p><strong>Assignment</strong></p>
            <p>We may assign our interest in this Agreement. You may not assign or transfer this Agreement. We may also assign or delegate certain of our rights and responsibilities under this Agreement to independent contractors or other third parties.</p>
            <p><strong>Headings</strong></p>
            <p>The heading references herein are for convenience purposes only, do not constitute a part of these Terms of Service, and shall not be deemed to limit or affect any of the provisions hereof.</p>
            <p><strong>Entire Agreement</strong></p>
            <p>This is the entire agreement between us relating to the subject matter herein and shall not be modified except in writing, signed by both parties, or by a change to this Agreement made by Fine Grounds as set forth above.<br></p>
            <p>Fine Grounds can be contacted by email at info@finegrounds.app.</p>
		</div>
	</div>
</template>

<script>

export default {

	name: 'TermsOfUsePage',

};

</script>
